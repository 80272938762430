import Vue from 'vue';
import VueI18n from 'vue-i18n'
import App from './App.vue';
import router from './router';
import store from './store';
import './scss/style.scss';
import Vuex from 'vuex'
import VueAxios from 'vue-axios'
import axios from 'axios';
import VueScrollTo from 'vue-scrollto';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Notifications from 'vue-notification'
import VueRouterBackButton from 'vue-router-back-button'
import de from './locales/de.json'
import en from './locales/en.json'
import tr from './locales/tr.json'
import ar from './locales/ar.json'
import uk from './locales/uk.json'
// import './scss/style-rtl.scss';


// import "vue-select/dist/vue-select.css";

Vue.config.productionTip = true;
Vue.prototype.modals = true;
export const bus = new Vue({});

/*config*/
/*id of the current app*/
Vue.prototype.$appId= '2';
Vue.prototype.$appName = "Kiel.RefugeeCompass";
Vue.prototype.$institutionId= '1';
Vue.prototype.$offerId= '2';
Vue.prototype.$city= 'Kiel';
Vue.prototype.$backendUrl = "https://bildungsangebote-kiel.de";
Vue.prototype.$backendApiUrl = "https://bildungsangebote-kiel.de/api/";
Vue.prototype.$impressumContentId = 10291;
Vue.prototype.$datenschutzContentId = 10281;
Vue.prototype.$barrierefreiheitsContentId = 244129;
Vue.prototype.$shortDescriptionWordCount = 215;

Vue.use(Vuex);
Vue.use(VueI18n)
Vue.use(VueScrollTo);
Vue.use(Notifications);
Vue.use(VueAxios, axios);
Vue.use(VueRouterBackButton, { router });

const i18n = new VueI18n({
  locale: 'de',
  messages: {
    de,
    en,
    tr,
    ar,
    uk
  }
})

/*Initialisieren von Loading*/
Vue.use(Loading, {
  color: 'rgb(148, 192, 31)',
  loader: 'dots',
  width: 140,
  FullPage: true
});

new Vue({
 i18n,
 router,
 store,
 render: function (h) { return h(App) }
}).$mount('#app')
